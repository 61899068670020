import React from 'react'
import Link from '@mui/material/Link';

import {  
    Box,
    Button,
    Stack,
    TextField
} from '@mui/material'
import Title from './Title'
import Paragraph from './Paragraph'
const handleChatButtonClick = () => {
    window.open("https://wa.me/917639568608", "_blank");
  };
const Details = () => {

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            phone: data.get('phone'),
        });
    }

 
    return (
        <Stack 
        component='section'
        direction="column"
        justifyContent= 'center'
        alignItems='center'
        sx={{
            py: 10,
            px: 2,
        }}
        >
            <Title 
            text={
                'Contact Us'
                } 
            textAlign={'center'}
            />
            <Paragraph 
            text={
                    'If you are interested in our services, please contact us,\
                 and we will promptly reach out to you on WhatsApp\
                  to discuss and fulfill your requirements'
            }
            maxWidth = {'sm'}
            mx={0}
            textAlign={'center'}
            />

            <Box 
            component="form" 
            noValidate 
            onSubmit={handleSubmit} 
            sx={{ 
                mt: 1,
                py: 2
            }}>
       
  

       <Link
      href="https://wa.me/917639568608"
      variant="body2"
      sx={{
        color: '#414141',
        "&:hover": {
          color: '#1c2859',
        }
      }}
    >
      <Button
        variant="contained"
        fullWidth
        type="submit"
        size="medium"
        sx={{
          fontSize: '0.9rem',
          textTransform: 'capitalize',
          py: 2,
          mt: 3,
          mb: 2,
          borderRadius: 0,
          backgroundColor: '#14192d',
          '&:hover': {
            backgroundColor: '#1e2a5a',
          },
        }}
        onClick={handleChatButtonClick}
      >
        Chat On Whatsapp
      </Button>
    </Link>
            </Box>
        </Stack>
    )
}

export default Details;